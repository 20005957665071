import styled, { css } from 'styled-components';

export const LOGO_SIZE_MEDIUM = 'logo/size/medium';
export const AVAILABLE_LOGO_SIZES = [LOGO_SIZE_MEDIUM];

export const LOGO_SIZES = {
  [LOGO_SIZE_MEDIUM]: css`
    height: 26px;
    width: 177px;
  `,
};

const LogoImageStyle = styled.img`
  ${({ size }) => LOGO_SIZES[size]}
`;

export default LogoImageStyle;
