import React from 'react';
import PropTypes from 'prop-types';

import Typography, { TYPOGRAPHY_BUTTON } from 'components/Typography';

import { ButtonStyled, ContentWrapper, ButtonIconStyled } from './ButtonStyles';

const Button = ({ children, icon, onClick, type, disabled }) => {
  return (
    <ButtonStyled type={type} onClick={onClick} disabled={disabled}>
      <ContentWrapper>
        <Typography type={TYPOGRAPHY_BUTTON}>{children}</Typography>
        {icon && <ButtonIconStyled type={icon} />}
      </ContentWrapper>
    </ButtonStyled>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default Button;
