import styled from 'styled-components';

import { BROWN_COLOR } from 'common/constants/style/color';

import media from 'util/mediaQuery';

export const CreateAccountPageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;

  ${media.TABLET`
    flex-direction: column;
  `}

  ${media.PHONE`
    flex-direction: column;
  `}
`;

export const LogoWrapper = styled.div`
  margin: 0 0 32px 0;
`;

export const InformationContainer = styled.div`
  flex: 0.51;
  height: 100vh;
  padding: 32px 80px;
  box-sizing: border-box;
  background-color: ${BROWN_COLOR};
  position: relative;

  ${media.TABLET`
    padding: 32px 16px;
    min-height: 210px;
    height: 100%;
  `}

  ${media.PHONE`
    padding: 32px 16px;
    min-height: 210px;
    height: 100%;
  `}
`;

export const InformationWrapper = styled.div`
  max-width: 535px;
  width: 100%;
  position: absolute;
  right: 0;
  padding-right: inherit;
  box-sizing: border-box;

  ${media.TABLET`
    left: 0;
    padding-left: inherit;
  `}

  ${media.PHONE`
    left: 0;
    padding-left: inherit;
  `}
`;

export const FormContainer = styled.div`
  flex: 0.49;
  height: 100vh;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 85px 24px;
  overflow-y: scroll;

  ${media.TABLET`
    padding: 32px 16px;
  `}

  ${media.PHONE`
    padding: 32px 16px;
  `}
`;
