import styled from 'styled-components';
import { Form } from 'formik';

import media from 'util/mediaQuery';

const FormStyled = styled(Form)`
  margin: 0 0 48px 0;
  padding: 0;
  max-width: 376px;

  > input:not(:last-of-type) {
    margin: 0 0 24px 0;
  }

  ${media.TABLET`
    max-width: 100%;
  `}

  ${media.PHONE`
    max-width: 100%;
  `}
`;

export default FormStyled;
