import React from 'react';
import PropTypes from 'prop-types';

import Typography, { TYPOGRAPHY_LABEL, TYPOGRAPHY_LABEL_ERROR } from 'components/Typography';

import { FormikFieldStyled, LabelWraapper } from './InputStyles';

const InputField = ({
  type,
  placeholder,
  onChange,
  name,
  value,
  label,
  errorMessaage,
  isActiveFilled,
  isPristine,
}) => {
  return (
    <>
      {label && (
        <LabelWraapper>
          <Typography type={TYPOGRAPHY_LABEL}>{label}</Typography>
          {errorMessaage && <Typography type={TYPOGRAPHY_LABEL_ERROR}>{errorMessaage}</Typography>}
        </LabelWraapper>
      )}
      <FormikFieldStyled
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        value={value}
        errorMessage={errorMessaage}
        isActiveFilled={isActiveFilled}
        isPristine={isPristine}
      />
    </>
  );
};

InputField.propTypes = {
  activeError: PropTypes.string,
  errorMessaage: PropTypes.string,
  isActiveFilled: PropTypes.bool,
  isPristine: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default InputField;
