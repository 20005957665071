import React from 'react';

import GlobalStyle from 'components/GlobalStyle';

import CreateAccountPage from 'pages/CreateAccount';

const App = () => (
  <>
    <GlobalStyle />
    <CreateAccountPage />
  </>
);

export default App;
