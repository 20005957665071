import styled from 'styled-components';

import { WHITE_COLOR, ORAGNE_COLOR } from 'common/constants/style/color';
import Icon from 'components/Icon';

import media from 'util/mediaQuery';

const BUTTON_SIZE_AUTO = 'button/size/auto';
const BUTTON_SIZE_MD = 'button/size/md';

const BUTTON_SIZES = {
  [BUTTON_SIZE_AUTO]: 'auto',
  [BUTTON_SIZE_MD]: '150px',
};

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ButtonIconStyled = styled(Icon)`
  margin: 0 0 0 8px;
`;

export const ButtonStyled = styled.button`
  padding: 0 24px;
  min-width: 105px;
  width: ${({ size }) => BUTTON_SIZES[size] || 'auto'};
  height: 48px;
  border-radius: 6px;
  border: none;
  color: ${WHITE_COLOR};
  background-color: ${ORAGNE_COLOR};
  outline: 0;
  justify-content: center;
  align-items: center;

  ${media.TABLET`
    width: 100%;
  `}

  ${media.PHONE`
    width: 100%;
  `}

  :disabled {
    opacity: 0.5;
  }

  :hover {
    cursor: pointer;
  }
`;
