import React from 'react';
import PropTypes from 'prop-types';

import WhiteRightArrow from './white-arrow-right.svg';
import WhiteArrowRightStyled from './IconStyles';

export const ICON_WHITE_RIGHT_ARROW = 'icon/type/white-arrow-right';

const AVAILALBE_ICONS = [ICON_WHITE_RIGHT_ARROW];

const ICONS = {
  [ICON_WHITE_RIGHT_ARROW]: {
    src: WhiteRightArrow,
    styledComponent: WhiteArrowRightStyled,
  },
};

const Icon = ({ type, className }) => {
  const IconBundle = ICONS[type] || null;
  if (!IconBundle) return null; // Throw error or warning here.
  const IconComponenet = IconBundle.styledComponent;
  return <IconComponenet className={className} src={IconBundle.src} />;
};

Icon.propTypes = {
  className: PropTypes.string.isRequired,
  type: PropTypes.oneOf(AVAILALBE_ICONS).isRequired,
};

export default Icon;