import React, { useState } from 'react';
import { Formik } from 'formik';

import Input from 'components/Input';
import Button from 'components/Button';
import Form from 'components/Form';
import { ICON_WHITE_RIGHT_ARROW } from 'components/Icon';

import getFieldError from 'util/getFieldError';

import { FORM_ONBOARDING_BASIC_INFORMATION_SCHEMA } from 'common/constants/formValidation';

const intialState = {
  firstName: '',
  lastName: '',
  address: '',
  addressTwo: '',
};

const BasicInformationForm = () => {
  const [formValues] = useState(intialState);

  const onFormSubmitted = values => {
    const { firstName, lastName, address, addressTwo } = values;
    // eslint-disable-next-line no-undef
    // eslint-disable-next-line no-alert
    window.alert(
      // eslint-disable-next-line max-len
      `First Name: ${firstName}\n Last Name: ${lastName}\n Address: ${address}\n Address Two: ${addressTwo}`,
    );
  };

  return (
    <Formik
      initialValues={formValues}
      onSubmit={onFormSubmitted}
      validationSchema={FORM_ONBOARDING_BASIC_INFORMATION_SCHEMA}>
      {({ handleSubmit, handleChange, values, errors, touched, isValid }) => (
        <>
          <Form>
            <Input
              label="First Name"
              type="text"
              onChange={handleChange}
              name="firstName"
              value={values.firstName}
              isPristine={!touched.firstName}
              isActiveFilled={touched.firstName}
              errorMessaage={getFieldError(errors.firstName, touched.firstName)}
            />
            <Input
              label="Last Name"
              type="text"
              onChange={handleChange}
              name="lastName"
              value={values.lastName}
              isPristine={!touched.lastName}
              isActiveFilled={touched.lastName}
              errorMessaage={getFieldError(errors.lastName, touched.lastName)}
            />
            <Input
              label="Address"
              name="address"
              onChange={handleChange}
              value={values.address}
              type="text"
              isPristine={!touched.address}
              isActiveFilled={touched.address}
              errorMessaage={getFieldError(errors.address, touched.address)}
            />
            <Input
              name="addressTwo"
              label="Address 2"
              type="text"
              value={values.addressTwo}
              isPristine={!touched.addressTwo}
              isActiveFilled={touched.addressTwo}
              onChange={handleChange}
            />
          </Form>
          <Button
            icon={ICON_WHITE_RIGHT_ARROW}
            type="submit"
            onClick={handleSubmit}
            size="auto"
            disabled={!isValid}>
            Next
          </Button>
        </>
      )}
    </Formik>
  );
};

export default BasicInformationForm;
