import React from 'react';

import Typography, { TYPOGRAPHY_HEADER, TYPOGRAPHY_PARAGRAPH } from 'components/Typography';
import Logo, { LOGO_SIZE_MEDIUM } from 'components/Logo';
import BasicInformationForm from 'pages/CreateAccount/BasicInformationForm';

import {
  CreateAccountPageWrapper,
  LogoWrapper,
  InformationContainer,
  FormContainer,
  InformationWrapper,
} from 'pages/CreateAccount/CreateAccountStyle';

const CreateAccount = () => {
  return (
    <CreateAccountPageWrapper>
      <InformationContainer>
        <InformationWrapper>
          <LogoWrapper>
            <Logo size={LOGO_SIZE_MEDIUM} />
          </LogoWrapper>
          <Typography type={TYPOGRAPHY_HEADER}>Welcome</Typography>
          <Typography type={TYPOGRAPHY_PARAGRAPH}>
            Please tell us a bit about yourself to get started.
          </Typography>
        </InformationWrapper>
      </InformationContainer>
      <FormContainer>
        <BasicInformationForm />
      </FormContainer>
    </CreateAccountPageWrapper>
  );
};

CreateAccount.propTypes = {};

export default CreateAccount;
