import React from 'react';
import PropTypes from 'prop-types';

import LogoImageStyle, { AVAILABLE_LOGO_SIZES } from './LogoStyle';

import logo from './Logo.svg';

const Logo = ({ size }) => <LogoImageStyle src={logo} size={size} alt="logo" />;

Logo.propTypes = {
  size: PropTypes.oneOf(AVAILABLE_LOGO_SIZES).isRequired,
};

export default Logo;
