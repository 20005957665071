import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonTypography,
  HeaderTypography,
  ParagraphTypography,
  LabelTypography,
  LabelErrorTypography,
} from './TypographyStyles';

export const TYPOGRAPHY_BUTTON = 'typography/type/button';
export const TYPOGRAPHY_HEADER = 'typography/type/header';
export const TYPOGRAPHY_PARAGRAPH = 'typography/type/paragraph';
export const TYPOGRAPHY_LABEL = 'typography/type/label';
export const TYPOGRAPHY_LABEL_ERROR = 'typography/type/labelError';

const AVAILABLE_TYPOGRAPHY_TYPES = [
  TYPOGRAPHY_BUTTON,
  TYPOGRAPHY_HEADER,
  TYPOGRAPHY_PARAGRAPH,
  TYPOGRAPHY_LABEL,
  TYPOGRAPHY_LABEL_ERROR,
];

const TYPOGRAPHY_COMPONENTS = {
  [TYPOGRAPHY_BUTTON]: ButtonTypography,
  [TYPOGRAPHY_HEADER]: HeaderTypography,
  [TYPOGRAPHY_PARAGRAPH]: ParagraphTypography,
  [TYPOGRAPHY_LABEL]: LabelTypography,
  [TYPOGRAPHY_LABEL_ERROR]: LabelErrorTypography,
};

const Typography = ({ type, children }) => {
  const TypographyComponent = TYPOGRAPHY_COMPONENTS[type];
  return <TypographyComponent>{children}</TypographyComponent>;
};

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(AVAILABLE_TYPOGRAPHY_TYPES).isRequired,
};

export default Typography;
