import { createGlobalStyle } from 'styled-components';

import { WHITE_COLOR } from 'common/constants/style/color';

const GlobalStyle = createGlobalStyle`
  html {
    @import url('https://fonts.googleapis.com/css?family=Merriweather:100,400,300,700,500|Roboto:100,400,300,700,500');

    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: ${WHITE_COLOR};
  }

  body {
    margin: 0;
  }
`;

export default GlobalStyle;
