import styled, { css } from 'styled-components';

import {
  WHITE_COLOR,
  LIGHT_GRAY_COLOR,
  DARK_GRAY_COLOR,
  RED_COLOR,
} from 'common/constants/style/color';

export const FONT_ROBOTO = `'Roboto', sans-serif`;
export const FONT_MERRIWEATHER = `'Merriweather', serif`;

export const ButtonTypography = styled.span`
  display: block;
  font-size: 16px;
  font-family: ${FONT_ROBOTO};
  font-weight: 700;
  color: ${WHITE_COLOR};
`;

export const HeaderTypography = styled.h1`
  font-family: ${FONT_ROBOTO};
  font-size: 32px;
  color: ${WHITE_COLOR};
  margin: 16px 0 16px 0;
`;

export const ParagraphTypography = styled.p`
  font-family: ${FONT_MERRIWEATHER};
  font-size: 14px;
  color: ${LIGHT_GRAY_COLOR};
`;

export const LabelTypography = styled.label`
  font-family: ${FONT_MERRIWEATHER};
  font-size: 12px;
  color: ${DARK_GRAY_COLOR};
  text-transform: uppercase;
`;

export const LabelErrorTypography = styled.span`
  display: block;
  font-family: ${FONT_MERRIWEATHER};
  font-size: 12px;
  color: ${RED_COLOR};
  margin: 0 0 0 3px;
`;

export const InputTypographyStyle = css`
  font-family: ${FONT_ROBOTO};
  font-size: 14px;
`;
