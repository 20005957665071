export const BROWN_COLOR = '#585858';
export const WHITE_COLOR = '#ffffff';
export const LIGHT_GRAY_COLOR = '#eaeaea';
export const DARK_GRAY_COLOR = '#666666';
export const RED_COLOR = '#e70000';
export const BLUE_COLOR = '#00adee';
export const ORAGNE_COLOR = '#ffab44';

export const AVAILABLE_COLORS = [
  BROWN_COLOR,
  WHITE_COLOR,
  LIGHT_GRAY_COLOR,
  DARK_GRAY_COLOR,
  RED_COLOR,
  BLUE_COLOR,
  ORAGNE_COLOR,
];
