import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

import { InputTypographyStyle } from 'components/Typography';

import { LIGHT_GRAY_COLOR, RED_COLOR, BLUE_COLOR, WHITE_COLOR } from 'common/constants/style/color';

const getBorderColor = ({ errorMessage, isActiveFilled }) => {
  if (errorMessage) return RED_COLOR;
  if (isActiveFilled) return BLUE_COLOR;
  return LIGHT_GRAY_COLOR;
};

export const LabelWraapper = styled.div`
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row;
`;

export const FormikFieldStyled = styled(
  ({ isPristine, errorMessage, isActiveFilled, ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Field {...props} />
  ),
)`
  ${InputTypographyStyle};
  width: 100%;
  height: 48px;
  padding: 0 16px 0 16px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: ${({ isPristine }) => (isPristine ? LIGHT_GRAY_COLOR : WHITE_COLOR)};
  border: 1px solid
    ${({ errorMessage, isActiveFilled }) => getBorderColor({ errorMessage, isActiveFilled })};
  outline: 0;
`;
