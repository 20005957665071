import * as yup from 'yup';

export const FORM_ERROR_MESSAGE_REQUIRED = 'REQUIRED';

export const STRING_VALIDATION_REQUIRED = yup.string().required(FORM_ERROR_MESSAGE_REQUIRED);
export const STRING_VALIDATION_OPTIONAL = yup.string();

export const FORM_ONBOARDING_BASIC_INFORMATION_SCHEMA = yup.object().shape({
  address: STRING_VALIDATION_REQUIRED,
  addressTwo: STRING_VALIDATION_OPTIONAL,
  firstName: STRING_VALIDATION_REQUIRED,
  lastName: STRING_VALIDATION_REQUIRED,
});
