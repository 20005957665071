import { css } from 'styled-components';

const MEDIA_SIZES = {
  PHONE: (...args) => css`
    @media (max-width: 23.5em) {
      ${css(...args)};
    }
  `,
  TABLET: (...args) => css`
    @media (min-width: 23.6em) and (max-width: 61em) {
      ${css(...args)};
    }
  `,
  DESKTOP: (...args) => css`
    @media (min-width: 62em) {
      ${css(...args)};
    }
  `,
};

export default MEDIA_SIZES;
